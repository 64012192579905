<template>
  <div class="share-pop" style="width: 274px; height: 596px">
    <div
      style="
        font-size: 20px;
        color: #ffffff;
        font-weight: bold;
        margin: 0 62px;
        padding-top: 38px;
        position: relative;
      "
    >
      <div style="text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25)">我完成了</div>
      <div
        class="work-name"
        style="text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);font-size: 24px;"
      >
        {{ shareData.work_name }}
      </div>
      <div style="text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25)">真的很棒</div>
      <div
        class="share-tip-msg"
        style="
          width: 76px;
          height: 66px;
          font-size: 12px;
          top: 9px;
          right: -30px;
        "
      >
        <div style="margin: 6px;text-align: center;">长按保存分享给好友</div>
      </div>
    </div>

    <div
      class="share-mian"
      style="border-radius: 15px; margin: 10px 22px; padding: 8px 0"
    >
      <div
        class="share-center-main"
        style="border-radius: 15px; margin: 0 8px; height: 353px"
      >
        <div
          class="work-title"
          style="
            width: 110px;
            height: 28px;
            border-radius: 0px 130px 130px 0;
            margin-left: -8px;
            position: relative;
            top: 10px;
          "
        >
          <img
            src="../../assets/img/share/radius-icon.png"
            alt=""
            style="width: 16px; height: 16px; margin: 0 7px"
          />
          <div style="font-size: 14px">作品封面</div>
        </div>
        <div
          class="work-img"
          style="width: 176px; height: 120px;margin: 20px; auto"
        >
          <img
            v-if="
              shareData.work_image_url !== '' &&
              shareData.work_image_url != undefined
            "
            :src="shareData.work_image_url"
            alt=""
            style="border-radius: 10px"
          />
          <img v-else :src="workImg" alt="" style="border-radius: 10px" />
        </div>
        <div
          class="boby-msg"
          style="
            width: 145px;
            height: 138px;
            border-radius: 15px;
            margin-right: 14px;
          "
        >
          <div style="margin: 14px">
            <div style="font-size: 14px; color: #ffc000">
              我家宝贝：{{ shareData.user_name }}
            </div>
            <div style="font-size: 16px; text-align: center; margin: 6px 0">
              {{ shareData.bureau_name }}
            </div>
            <div style="font-size: 12px">学习果宝编程课程</div>
            <div style="font-size: 10px; position: absolute; bottom: 10px">
              累计完成作品
              <span style="font-size: 16px; color: #ffc000">{{
                shareData.work_num
              }}</span>
              件
            </div>
          </div>
        </div>

        <img
          src="../../assets/img/share/robot.png"
          alt=""
          style="position: absolute; width: 88px; right: 0; top: -30px"
        />
        <img
          src="../../assets/img/share/robot-2.png"
          alt=""
          style="position: absolute; height: 217px; bottom: -10px; left: -30px"
        />
      </div>
    </div>

    <div
      class="school-msg-qrcode"
      style="height: 83px; border-radius: 0 0 15px 15px"
    >
      <div class="address-phone">
        <div class="school-msg">
          <img
            src="../../assets/img/share/school-icon1.png"
            alt=""
            style="width: 10px; height: 10px; margin: 0 12px"
          />
          <div class="msg-it" style="font-size: 10px">
            {{ shareData.bureau_name }}
          </div>
        </div>
        <div class="school-msg">
          <img
            src="../../assets/img/share/school-icon2.png"
            alt=""
            style="width: 10px; height: 10px; margin: 0 12px"
          />
          <div class="msg-it" style="font-size: 10px">
            <span
              v-if="
                shareData.group_phone !== undefined &&
                shareData.group_phone !== ''
              "
              >{{ shareData.group_phone }}</span
            >
            <span v-else>{{ shareData.bureau_phone }}</span>
          </div>
        </div>
        <div class="school-msg">
          <img
            src="../../assets/img/share/school-icon3.png"
            alt=""
            style="width: 10px; height: 10px; margin: 0 12px"
          />
          <div class="msg-it" style="font-size: 10px">
            {{ shareData.bureau_location }}
          </div>
        </div>
      </div>
      <div class="tip-qrcode">
        <div
          style="
            width: 70px;
            height: 14px;
            line-height: 14px;
            font-size: 8px;
            color: #1748a2;
            background: #ffffff;
            border-radius: 7px;
            text-align: center;
          "
        >
          扫码查看作品
        </div>
        <img
          :src="shareData.qr_code"
          alt=""
          style="width: 50px; height: 50px"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["shareData"],
  data() {
    return {
      workImg: "./img/bf-works.png",
    };
  },
};
</script>
<style lang="less" scoped>
.share-pop {
  background-image: url("../../assets/img/share/share-pop-bck.png");
  background-size: 100% 100%;
  .work-name {
    color: #ffc000;
    width: 5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .share-tip-msg {
    position: absolute;
    font-weight: bold;
    color: #1556ba;
    background-image: url("../../assets/img/share/tip-user-msg.png");
    background-size: 100% 100%;
  }

  .share-mian {
    background: #8ebaef;
    .share-center-main {
      background: #ffffff;
      position: relative;
      .work-title {
        background: linear-gradient(180deg, #89c5ea 0%, #1d66bf 100%);
        color: #ffffff;
        display: flex;
        align-items: center;
      }
      .work-img {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .boby-msg {
    color: #ffffff;
    font-weight: bold;
    background: linear-gradient(137deg, #83a3de 0%, #285fc5 100%);
    display: flex;
    margin-left: auto;
    position: relative;
  }

  .school-msg-qrcode {
    background: linear-gradient(180deg, #90d4f1 0%, #6cbbeb 100%);
    color: #1748a2;
    font-weight: bold;
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    .address-phone {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      .school-msg {
        display: flex;
        align-items: center;

        .msg-it {
          width: 3.8rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
      }
    }
    .tip-qrcode {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
    }
  }
}
</style>
