import LessonVideo from "../Home/LessonVideo";
export function wxShareFun(data) {
    console.log(data)
    const oScript = document.createElement("script");
    oScript.type = "text/javascript";
    oScript.src = "https://res2.wx.qq.com/open/js/jweixin-1.6.0.js";
    document.body.appendChild(oScript);

    var url = location.href.split("#")[0];
    console.log(url);
    LessonVideo.getSharedUrl({ scene: "as", url: url }, ({ type, list }) => {
        if (type === 200) {
            console.log(list);
            list = JSON.parse(list);
            wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: list.appId, // 必填，公众号的唯一标识
                timestamp: list.timestamp, // 必填，生成签名的时间戳
                nonceStr: list.nonceStr, // 必填，生成签名的随机串
                signature: list.signature, // 必填，签名，见附录1
                jsApiList: [
                    "updateAppMessageShareData", //自定义“分享给朋友”及“分享到QQ”按钮的分享内容
                    "updateTimelineShareData", //自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
                ], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
            });

            wx.ready((res) => {
                //分享给朋友
                wx.updateAppMessageShareData({
                    title: data.name, // 分享标题
                    desc: data.content, // 分享描述
                    link: window.location.href, // 分享链接
                    imgUrl: data.image, // 分享图标
                    type: "link", // 分享类型,music、video或link，不填默认为link
                    success: function () {
                        // 用户确认分享后执行的回调函数
                        console.log("分享成功");
                    },
                    cancel: function () {
                        // 用户取消分享后执行的回调函数
                    },
                });

                wx.updateTimelineShareData({
                    title: data.name, // 分享标题
                    link: window.location.href, // 分享链接
                    imgUrl: 'https://img0.baidu.com/it/u=1721391133,702358773&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=625', // 分享图标
                    success: function () {
                        // 用户确认分享后执行的回调函数
                    },
                    cancel: function () {
                        // 用户取消分享后执行的回调函数
                    },
                });
            });
        }
    });
}