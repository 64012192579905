var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"share-pop",staticStyle:{"width":"274px","height":"596px"}},[_c('div',{staticStyle:{"font-size":"20px","color":"#ffffff","font-weight":"bold","margin":"0 62px","padding-top":"38px","position":"relative"}},[_c('div',{staticStyle:{"text-shadow":"0px 4px 5px rgba(0, 0, 0, 0.25)"}},[_vm._v("我完成了")]),_c('div',{staticClass:"work-name",staticStyle:{"text-shadow":"0px 4px 5px rgba(0, 0, 0, 0.25)","font-size":"24px"}},[_vm._v(" "+_vm._s(_vm.shareData.work_name)+" ")]),_c('div',{staticStyle:{"text-shadow":"0px 4px 5px rgba(0, 0, 0, 0.25)"}},[_vm._v("真的很棒")]),_vm._m(0)]),_c('div',{staticClass:"share-mian",staticStyle:{"border-radius":"15px","margin":"10px 22px","padding":"8px 0"}},[_c('div',{staticClass:"share-center-main",staticStyle:{"border-radius":"15px","margin":"0 8px","height":"353px"}},[_vm._m(1),_c('div',{staticClass:"work-img",staticStyle:{"width":"176px","height":"120px","margin":"20px"}},[(
            _vm.shareData.work_image_url !== '' &&
            _vm.shareData.work_image_url != undefined
          )?_c('img',{staticStyle:{"border-radius":"10px"},attrs:{"src":_vm.shareData.work_image_url,"alt":""}}):_c('img',{staticStyle:{"border-radius":"10px"},attrs:{"src":_vm.workImg,"alt":""}})]),_c('div',{staticClass:"boby-msg",staticStyle:{"width":"145px","height":"138px","border-radius":"15px","margin-right":"14px"}},[_c('div',{staticStyle:{"margin":"14px"}},[_c('div',{staticStyle:{"font-size":"14px","color":"#ffc000"}},[_vm._v(" 我家宝贝："+_vm._s(_vm.shareData.user_name)+" ")]),_c('div',{staticStyle:{"font-size":"16px","text-align":"center","margin":"6px 0"}},[_vm._v(" "+_vm._s(_vm.shareData.bureau_name)+" ")]),_c('div',{staticStyle:{"font-size":"12px"}},[_vm._v("学习果宝编程课程")]),_c('div',{staticStyle:{"font-size":"10px","position":"absolute","bottom":"10px"}},[_vm._v(" 累计完成作品 "),_c('span',{staticStyle:{"font-size":"16px","color":"#ffc000"}},[_vm._v(_vm._s(_vm.shareData.work_num))]),_vm._v(" 件 ")])])]),_c('img',{staticStyle:{"position":"absolute","width":"88px","right":"0","top":"-30px"},attrs:{"src":require("../../assets/img/share/robot.png"),"alt":""}}),_c('img',{staticStyle:{"position":"absolute","height":"217px","bottom":"-10px","left":"-30px"},attrs:{"src":require("../../assets/img/share/robot-2.png"),"alt":""}})])]),_c('div',{staticClass:"school-msg-qrcode",staticStyle:{"height":"83px","border-radius":"0 0 15px 15px"}},[_c('div',{staticClass:"address-phone"},[_c('div',{staticClass:"school-msg"},[_c('img',{staticStyle:{"width":"10px","height":"10px","margin":"0 12px"},attrs:{"src":require("../../assets/img/share/school-icon1.png"),"alt":""}}),_c('div',{staticClass:"msg-it",staticStyle:{"font-size":"10px"}},[_vm._v(" "+_vm._s(_vm.shareData.bureau_name)+" ")])]),_c('div',{staticClass:"school-msg"},[_c('img',{staticStyle:{"width":"10px","height":"10px","margin":"0 12px"},attrs:{"src":require("../../assets/img/share/school-icon2.png"),"alt":""}}),_c('div',{staticClass:"msg-it",staticStyle:{"font-size":"10px"}},[(
              _vm.shareData.group_phone !== undefined &&
              _vm.shareData.group_phone !== ''
            )?_c('span',[_vm._v(_vm._s(_vm.shareData.group_phone))]):_c('span',[_vm._v(_vm._s(_vm.shareData.bureau_phone))])])]),_c('div',{staticClass:"school-msg"},[_c('img',{staticStyle:{"width":"10px","height":"10px","margin":"0 12px"},attrs:{"src":require("../../assets/img/share/school-icon3.png"),"alt":""}}),_c('div',{staticClass:"msg-it",staticStyle:{"font-size":"10px"}},[_vm._v(" "+_vm._s(_vm.shareData.bureau_location)+" ")])])]),_c('div',{staticClass:"tip-qrcode"},[_c('div',{staticStyle:{"width":"70px","height":"14px","line-height":"14px","font-size":"8px","color":"#1748a2","background":"#ffffff","border-radius":"7px","text-align":"center"}},[_vm._v(" 扫码查看作品 ")]),_c('img',{staticStyle:{"width":"50px","height":"50px"},attrs:{"src":_vm.shareData.qr_code,"alt":""}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"share-tip-msg",staticStyle:{"width":"76px","height":"66px","font-size":"12px","top":"9px","right":"-30px"}},[_c('div',{staticStyle:{"margin":"6px","text-align":"center"}},[_vm._v("长按保存分享给好友")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"work-title",staticStyle:{"width":"110px","height":"28px","border-radius":"0px 130px 130px 0","margin-left":"-8px","position":"relative","top":"10px"}},[_c('img',{staticStyle:{"width":"16px","height":"16px","margin":"0 7px"},attrs:{"src":require("../../assets/img/share/radius-icon.png"),"alt":""}}),_c('div',{staticStyle:{"font-size":"14px"}},[_vm._v("作品封面")])])
}]

export { render, staticRenderFns }