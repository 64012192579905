<template>
    <div class="main">
        <div class="content-info-box" style="position: relative; z-index: 1; background: #ffffff">
            <div class="main-mask-box" :class="isSeeBigImg ? 'check-box' : ''">
                <img :src="theImgUrl" alt="" />
                <i class="el-icon-close" @click="isSeeBigImg = false"></i>
            </div>
            <div class="top">
                <i v-if="$route.query.type === 'gb-parent'" class="el-icon-arrow-left"
                    style="position: relative; left: 10px" @click="handleReturn"></i>
                <i v-else></i>
                <p class="title">成果展示</p>
                <!-- <img :src="shareButImg" alt="" @click="downImgClick"> -->
                <div><img :src="shareButImg" alt="" @click="downImgClick" /></div>
            </div>
            <div style="width: 100%; height: 70px"></div>
            <div class="content" style="padding-bottom: 30px">
                <div class="pick-box user-info">
                    <div class="con-box" style="border-radius: 10px">
                        <div class="info-box">
                            <div class="avater-box">
                                <img v-if="reportInfo.avatar_url === ''" src="../../assets/img/back-test2.png" alt="" />
                                <img v-else :src="reportInfo.avatar_url" alt="" />
                            </div>
                            <div class="text-box">
                                <p class="name">{{ reportInfo.nickname }}</p>
                                <p class="group" style="color: #999999">
                                    班级: {{ reportInfo.group_name }}
                                </p>
                            </div>
                        </div>
                        <div class="data-box" style="
                display: flex;
                flex-direction: row;
                justify-content: center;
              ">
                            <div class="" style="
                  flex: 1;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                ">
                                <div style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  ">
                                    <svg-icon icon-class="work-gather-icon" class="svg-icon-class" style="
                      width: 30px;
                      height: 30px;
                      color: #cacaca;
                      margin-left: -4px;
                    " />
                                    <span style="color: #333333ff">{{
                                        reportInfo.works_num
                                    }}</span>
                                </div>
                                <span style="font-size: 12px; color: #c4c4c4">已完成作品数</span>
                            </div>
                            <div class="line"></div>
                            <div class="" style="
                  flex: 1;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                ">
                                <div style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  ">
                                    <svg-icon icon-class="horologe-icon" class="svg-icon-class" style="
                      width: 30px;
                      height: 30px;
                      color: #cacaca;
                      margin-left: -4px;
                    " />
                                    <span style="color: #333333ff">{{ Math.ceil(reportInfo.elaspsed_time / 60) }}分钟</span>
                                </div>
                                <span style="font-size: 12px; color: #c4c4c4">本作品完成时长</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pick-box outcome-box">
                    <p class="title">成果展示</p>
                    <div class="con-box" style="border-radius: 10px">
                        <div class="work-box" style="position: relative" @click="worksClick">
                            <div class="img-box" v-if="reportInfo.report_img === ''">
                                <img :src="bfWorksImg" alt="" />
                            </div>
                            <div class="img-box" v-else>
                                <img :src="reportInfo.report_img" alt="" />
                            </div>
                            <div style="
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  background: rgba(18, 18, 18, 0.25);
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  border-radius: 10px;
                ">
                                <div class="btn">
                                    <img src="../../assets/img/report/play-btn.png" alt=""
                                        style="width: 40px; height: 40px" />
                                </div>
                                <p class="text" style="color: #f4f5fa; font-size: 16px; padding: 12px 0">
                                    运行成果展示
                                </p>
                            </div>
                        </div>
                        <div class="tip-box" style="display: flex; align-items: center">
                            <div class="img-btn">
                                <img src="../../assets/img/report/start51@2x.png" alt="" />
                            </div>
                            <span>&nbsp;小提示:点击作品下方的</span>
                            <div class="img-btn">
                                <img src="../../assets/img/report/qizi@2x.png" alt="" />
                            </div>
                            <span>&nbsp;或角色可运行</span>
                        </div>
                    </div>
                </div>
                <div class="pick-box study-box">
                    <p class="title">学习课时</p>
                    <div class="con-box" style="border-radius: 10px">
                        <div class="work-box">
                            <div class="img-box">
                                <img :src="reportInfo.lesson_image_url" alt="" />
                            </div>
                            <div class="tag-box" style="background: rgba(18, 18, 18, 0.25)">
                                <div v-for="(item, index) in reportInfo.comment_tags" :key="index"
                                    style="background: #f0f3f7; border-radius: 10px 10px 10px 0px">
                                    {{ item.tag_name }}
                                </div>
                            </div>
                        </div>
                        <div class="tag-title" style="
                display: flex;
                align-items: center;
                justify-content: space-between;
              ">
                            <span style="font-weight: bold; font-size: 18px">{{
                                reportInfo.lesson_name
                            }}</span>
                            <span style="color: #cccccc; font-size: 12px">{{
                                reportInfo.work_create_time
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="pick-box evaluate-box">
                    <p class="title">老师评价</p>
                    <div class="con-box" style="border-radius: 10px">
                        <div class="star-box">
                            <div class="star-item" v-for="(staritem, index) in reportInfo.comment_options" :key="index">
                                <div class="star-title">{{ staritem.field_name }}</div>
                                <div class="star-list">
                                    <div class="star-img-box" v-for="item in staritem.field_value" :key="item">
                                        <img src="../../assets/img/report/start51@2x.png" alt="" />
                                    </div>
                                    <div class="star-img-box" v-for="item in 5 - staritem.field_value" :key="item + 'only'">
                                        <img src="../../assets/img/report/start52@2x.png" alt="" />
                                    </div>
                                </div>
                                <div class="extent">
                                    <span v-if="staritem.field_value < 2">加油</span>
                                    <span v-if="staritem.field_value === 2">再接再厉</span>
                                    <span v-if="staritem.field_value === 3">满意</span>
                                    <span v-if="staritem.field_value === 4">很棒</span>
                                    <span v-if="staritem.field_value > 4">非常棒</span>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div class="know-box" v-if="points.length != 0">
                            <p class="title">知识点</p>
                            <div class="content" style="background: #fafafa">
                                {{ points }}
                            </div>
                        </div>
                        <div class="know-box">
                            <p class="title">留言</p>
                            <div class="content" style="background: #fafafa">
                                {{ reportInfo.comment }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pick-box appraise-box" v-if="imgList.length > 0">
                <p class="title">查看评价图片</p>
                <div class="the-con-box">
                    <ul class="appraise-con-box" :style="{ width: imgList.length * 80 + '%' }">
                        <li class="item" :style="{ width: 100 / imgList.length + '%' }" v-for="(item, index) in imgList"
                            :key="index" @click="seeBigImg(item)">
                            <div class="img-box demo-image__preview">
                                <img :src="item" alt="" />
                            </div>
                            <div class="mask"></div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="pick-box appraise-box" v-if="videoList.length > 0" style="padding-bottom: 50px">
                <p class="title">播放评论视频</p>
                <div class="the-con-box">
                    <ul class="appraise-con-box" :style="{ width: videoList.length * 80 + '%' }">
                        <li class="item" :style="{ width: 100 / videoList.length + '%' }" v-for="(item, index) in videoList"
                            :key="index" @click="seeBigVideo(item)">
                            <div class="img-box demo-image__preview">
                                <!--                                <img :src="item.url" alt="">-->
                                <video controls :src="item" poster=" " alt="" controlslist="nodownload" contextmenu="false">
                                    <source :src="item" type="video/mp4" />
                                </video>
                            </div>
                            <!--                            <div class="mask"></div>-->
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!--        <LeavingVideo :videoUrl="videoUrl" v-if="videoAlertState"></LeavingVideo>-->
        <van-popup v-model="show" closeable :close-on-click-overlay="false">
            <!-- <div style="text-align: center; height: 20px; line-height: 46px">
                长按保存分享给好友
            </div> -->
            <div>
                <el-image round class="code-but-class" :src="imgUrl" fit="cover" style="width: 322px"></el-image>
            </div>
        </van-popup>

        <div style="opacity: 1; position: fixed; top: 0; z-index: -1">
            <div ref="imageWrapper">
                <!-- <HshareAlert :shareData="qrData"></HshareAlert> -->
                <SharePop :shareData="qrData"></SharePop>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../../models/Home/Api";
import LeavingVideo from "./LeavingVideo";
import html2canvas from "html2canvas";
import HshareAlert from "../../components/share/HshareAlert";
import { wxShareFun } from "@/models/WxShare/wxshare.js";
import SharePop from "../../components/share/SharePop.vue";

// const view = () => import('../pages/view.vue')
export default {
    setup() {
    },
    components: { LeavingVideo, HshareAlert ,SharePop},
    data() {
        return {
            shareButImg: "./img/share.png",
            bfWorksImg: "./img/bf-works.png",
            isSeeBigImg: false,
            theImgUrl: "../../assets/img/back-test.png",
            reportId: this.$route.query.target,
            reportInfo: {},
            imgList: [],
            videoList: [],
            videoUrl: "",
            qrData: {},
            show: false,
            imgUrl: "",
            points: "",
        };
    },
    created() {
        this.getData();
    },
    methods: {
        handleReturn() {
            window.location.href = `${this.$cdnUrl}/parents/#/dashboard`;
        },
        getData() {
            Api.fetchData(
                {
                    report_id: this.reportId,
                },
                "/bureau/v1/report",
                ({ type, list }) => {
                    this.reportInfo = list;
                    list.teacher_tickling.forEach((item, index) => {
                        if (item.type === "image") this.imgList.push(item.url);
                        if (item.type === "video") this.videoList.push(item.url);
                    });

                    list.points.forEach((item, index) => {
                        this.points += item.point_name;
                    });

                    this.qrData = {
                        work_name: list.work_name,
                        work_image_url: list.report_img,
                        bureau_avatar_url: list.avatar_url === '' ? '../../assets/img/back-test2.png' : list.avatar_url,
                        nikename: list.nikename,
                        bureau_phone: list.bureau_phone,
                        work_num: list.works_num,
                        bureau_location: list.bureau_location,
                        qr_code: list.qr_code,
                        bureau_name: list.bureau_name,
                        user_name: list.nickname,
                        group_phone: list.group_phone,
                    };
                    console.log(this.qrData)
                    setTimeout(() => {
                        html2canvas(this.$refs.imageWrapper, {
                            useCORS: true,
                            x: 0,
                            y: window.pageYOffset,
                        }).then((canvas) => {
                            let dataURL = canvas.toDataURL("image/png");
                            // console.log(dataURL);
                            this.imgUrl = dataURL;

                            if (this.imgUrl !== "") {
                                this.dialogTableVisible = true;
                            }
                        });
                    }, 500);
                    wxShareFun({
                        name: '我在果动编程完成一个作品',
                        image: 'https://storage.shubenji.cn/GuoBao/resource/9b98bc8052d8fcaa.png',
                        content: `我成功完成了${list.work_name}，我爱编程`,
                    });
                }
            );
        },
        seeBigImg(url) {
            this.theImgUrl = url;
            this.isSeeBigImg = true;
        },
        seeBigVideo(url) {
            this.videoUrl = url;
        },
        worksClick() {
            if (this.reportInfo.type === 'sb3') {
                this.$router.push({
                    path: "myWorks",
                    name: "MyWorks",
                    query: { target: this.$route.query.target },
                });
                window.location.href = `/scratch-old-html/tinyplay_sb3.html?target=${this.$route.query.target}`;
            } else if (this.reportInfo.type === 'sjr') {
                window.location.href = `/jr2/?target=${this.$route.query.target}`;
            } else if (this.reportInfo.type === 'guodongsjr') {
                window.location.href = `/jr2/?target=${this.$route.query.target}`;
            }

        },
        downImgClick() {
            this.show = true;
        },
    },
};
</script>
<style scoped>
.main {
    font-size: 0.5rem;
    background-color: #f6f9faff;
    /*padding-top: 2.24rem;*/
    margin-top: 100px;
    height: 118vh;
    overflow-y: auto;
    position: relative;
}

img {
    width: 100%;
    display: block;
}

.top {
    position: fixed;
    z-index: 1;
    top: 0;
    text-align: center;
    color: #fff;
    width: 100%;
    background-image: linear-gradient(to right, #ff8300ff, #ffc17fff);
    background-image: -webkit-linear-gradient(to right, #ff880b, #ffc07d);
    background-image: -o-linear-gradient(to right, #ff880b, #ffc07d);
    background-image: -moz-linear-gradient(to right, #ff880b, #ffc07d);
    /*标准写法*/
    background: linear-gradient(to right, #ff880b, #ffc07d);
    /*ie8以上*/
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorStr='#e89621',endColorStr='#f5c568',gradientType=1)";
    /*ie9以上*/
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr=#FF880B, endColorstr=#FFC07D);
    padding: 0.53rem 0;
    border-radius: 0 0 0.26rem 0.26rem;
    background-color: #f6f9faff;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top img {
    position: absolute;
    width: 0.74rem;
    right: 0.4rem;
    top: 0.5rem;
}

.content-info-box {
    margin-bottom: 3rem;
    width: 100%;
}

.content {
    padding: 0 0.42rem;
    height: auto;
}

.pick-box {
    padding-bottom: 0.15rem;
}

.pick-box .title {
    font-weight: bold;
    font-size: 0.5rem;
    color: #333333ff;
    margin-bottom: 0.21rem;
}

.pick-box .con-box {
    border-radius: 0.13rem;
    background-color: #fff;
    padding: 0.42rem;
    margin-bottom: 0.85rem;
    box-shadow: 0px 0px 0.26rem 0px #ddd;
    overflow: hidden;
}

.user-info .info-box {
    display: flex;
}

.user-info .info-box .avater-box {
    width: 1.49rem;
    height: 1.49rem;
    border-radius: 0.13rem;
    overflow: hidden;
    margin-right: 0.42rem;
}

.user-info .info-box .avater-box img {
    width: 100%;
}

.user-info .info-box .text-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.user-info .info-box .text-box .name {
    color: #333333ff;
    font-size: 0.5rem;
}

.user-info .info-box .text-box .group {
    color: #999999ff;
    font-size: 0.32rem;
    align-self: flex-end;
}

.user-info .data-box {
    display: flex;
    justify-content: space-between;
    color: #c4c4c4ff;
    margin-top: 0.85rem;
    padding: 0 0.21rem;
}

.user-info .data-box .item .info-box {
    display: flex;
    /* justify-content: space-between; */
}

.user-info .data-box .item .num {
    color: #666666ff;
    font-size: 0.5rem;
}

.user-info .data-box .item p {
    margin-top: 0.05rem;
    font-size: 0.32rem;
}

.user-info .data-box .line {
    width: 0.02rem;
    background: rgba(18, 18, 18, 0.10196078431372549);
}

.outcome-box .con-box,
.study-box .con-box {
    padding: 0;
}

.outcome-box .con-box .work-box {
    /*position: relative;*/
}

.outcome-box .con-box .work-box .img-box {
    /*overflow: hidden;*/
    /*width: 100%;*/
    /*max-height: 5rem;*/
}

.outcome-box .con-box .work-box .img-box img {
    /*width: 100%;*/
    /*border-radius: 0.13rem;*/
}

.outcome-box .con-box .work-box .btn {
    /*position: absolute;*/
    /*left: calc(50% - 0.6rem);*/
    /*width: 1.2rem;*/
    /*height: 1.2rem;*/
    /*top: 1.2rem;*/
}

.outcome-box .con-box .work-box .text {
    /*position: absolute;*/
    /*top: 2.69rem;*/
    /*color: #F4F5FAFF;*/
    /*font-size: 0.42rem;*/
    /*width: 100%;*/
    /*text-align: center;*/
}

.outcome-box .con-box .tip-box {
    padding-left: 0.42rem;
    padding-top: 0.21rem;
    padding-bottom: 0.72rem;
}

.outcome-box .con-box .tip-box .img-btn {
    width: 0.42rem;
    display: inline-block;
}

.outcome-box .con-box .tip-box span {
    color: #666666ff;
    font-size: 0.37rem;
    letter-spacing: 0.02rem;
}

.study-box .con-box .work-box {
    position: relative;
}

.study-box .con-box .work-box .img-box {
    border-radius: 0.13rem;
    overflow: hidden;
}

.study-box .con-box .work-box .tag-box {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    padding: 0.32rem 0.42rem;
    background: #12121240;
}

.study-box .con-box .work-box .tag-box div {
    margin-right: 0.37rem;
    color: #a0a0a0ff;
    font-size: 0.37rem;
    background-color: #f0f3f7ff;
    border-radius: 1rem;
    border-bottom-left-radius: 0;
    padding: 0 0.26rem;
}

.study-box .con-box .tag-title {
    padding: 0.21rem 0.32rem;
    color: #333333ff;
    font-size: 0.5rem;
}

.study-box .con-box .tag-text {
    display: flex;
    justify-content: space-between;
}

.study-box .con-box .tag-text div {
    color: #ccccccff;
    font-size: 0.32rem;
    padding: 0 0.32rem;
    margin-bottom: 0.32rem;
}

.evaluate-box .con-box .star-box {}

.evaluate-box .con-box .star-box .star-item {
    display: flex;
    margin-bottom: -0.2rem;
}

.evaluate-box .con-box .star-box .star-item .star-title {
    color: #666666ff;
    font-size: 0.32rem;
    /* margin-bottom: 0.4rem; */
    min-width: 2rem;
    line-height: 1.2rem;
}

.evaluate-box .con-box .star-box .star-item .star-list {
    display: flex;
    align-items: center;
    margin-right: 0.48rem;
}

.evaluate-box .con-box .star-box .star-item .star-list .star-img-box {
    width: 0.64rem;
    margin-right: 0.16rem;
}

.evaluate-box .con-box .star-box .star-item .extent {
    color: #999999ff;
    font-size: 0.32rem;
    line-height: 1.2rem;
}

.evaluate-box .con-box hr {
    margin: 0.42rem 0;
    border: 0.01rem solid #1212121a;
}

.evaluate-box .con-box .know-box {}

.evaluate-box .con-box .know-box .title {
    color: #666666ff;
    font-size: 0.5rem;
}

.evaluate-box .con-box .know-box .content {
    color: #999999ff;
    font-size: 0.37rem;
    border-radius: 0.26rem;
    background-color: #fafafaff;
    min-height: 3rem;
    padding: 0.21rem;
    margin-bottom: 0.21rem;
}

.appraise-box {
    padding-left: 0.42rem;
    margin-bottom: 1.06rem;
    /* width: 100%; */
}

.appraise-box:last-child {
    margin-bottom: 3rem;
}

.appraise-box .the-con-box {
    /* width: calc(100% - 0.42rem); */
    overflow-x: auto;
}

.appraise-box .the-con-box .appraise-con-box {
    display: flex;
    /* width: 500%; */
}

.appraise-box .the-con-box .appraise-con-box .item {
    /* width: 70%; */
    height: 7rem;
    position: relative;
    margin-right: 0.42rem;
    border-radius: 0.26rem;
    overflow: hidden;
}

.appraise-box .the-con-box .appraise-con-box img {
    width: 100%;
    height: 7rem;
}

.appraise-box .the-con-box .appraise-con-box video {
    width: 100%;
    height: 7rem;
}

.appraise-box .the-con-box .appraise-con-box .item .mask {
    position: absolute;
    background: #000;
    opacity: 0.3;
    width: 100%;
    height: 100%;
    top: 0;
}

.main-mask-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 0vh;
    width: 0vw;
    position: fixed;
    margin-bottom: 3rem;
    margin-left: 50%;
    margin-top: 50%;
    background: #0005;
    top: 0;
    z-index: 2;
    transition: all 0.2s;
}

.main-mask-box.check-box {
    height: 100vh;
    width: 100vw;
    margin: 0;
}

.main-mask-box .el-icon-close {
    display: none;
}

.check-box .el-icon-close {
    display: block;
    position: absolute;
    right: 0.3rem;
    top: 1rem;
    z-index: 3;
    color: #fff;
}

.check-box .el-icon-close::before {
    border: 0.06rem solid #fff;
    border-radius: 50%;
    padding: 0.2rem;
    font-weight: bold;
}

::v-deep .van-popup{
    background: none;
    border-radius: 0.5rem 0.5rem 0.7rem 0.7rem;
}
</style>
